
// import { typographyStyles } from "./utilities/_typography";
// import { animationStyles } from "./utilities/_animations";

const GlobalCss = ({ children }) => {
  // variableStyles();
  // positioningStyles();
  // spacingStyles();
  // borderStyles();
  // colorStyles();
  // shadowStyles();
  // typographyStyles();
  // typographyStyles
  // commonStyles();
  // animationStyles();
  // layoutStyles();
  // landingStyles();

  return children;
};

export default GlobalCss;
