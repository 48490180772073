import React from 'react';
import { SvgIcon } from "@mui/material";

const GithubLogo = props => {
    return (
        <SvgIcon {...props} >

            <svg xmlns="http://www.w3.org/2000/svg"
                class="svg-snoweb svg-theme-light"
                x="0"
                y="0"
                width="30"
                height="30"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid meet"
                fill="white"
            >


                <g transform="translate(20.00, 20.00) scale(0.60, 0.60)">
                    <path class="svg-fill-primary" d="M50,1.23A50,50,0,0,0,34.2,98.68c2.5.46,3.41-1.09,3.41-2.41s0-4.33-.07-8.5c-13.91,3-16.84-6.71-16.84-6.71-2.28-5.77-5.55-7.31-5.55-7.31-4.54-3.1.34-3,.34-3,5,.35,7.66,5.15,7.66,5.15C27.61,83.5,34.85,81.3,37.7,80a10.72,10.72,0,0,1,3.17-6.69C29.77,72.07,18.1,67.78,18.1,48.62A19.34,19.34,0,0,1,23.25,35.2c-.52-1.26-2.23-6.34.49-13.23,0,0,4.19-1.34,13.75,5.13a47.18,47.18,0,0,1,25,0C72.07,20.63,76.26,22,76.26,22c2.72,6.89,1,12,.49,13.23a19.28,19.28,0,0,1,5.14,13.42c0,19.21-11.69,23.44-22.83,24.67,1.8,1.55,3.4,4.6,3.4,9.26,0,6.69-.06,12.08-.06,13.72,0,1.34.9,2.89,3.44,2.4A50,50,0,0,0,50,1.23Z">
                    </path>

                </g>
            </svg>
        </SvgIcon>
    );
}

export default GithubLogo;